/* global System */

import loadAssets from './load-assets';

export default (path='/') => (name, noCss) => Promise.all([
  window.mqConfig.bwCompat ? System.import(`${path}js/${name}.js`) : import(`${path}js/${name}.mjs`),
  noCss || new Promise(
    (resolve, reject) => loadAssets(
      inject => inject(
        'link', {
          rel: 'stylesheet',
          type: 'text/css',
          href: `${path}css/${name}.css`
        },
        [resolve, reject]
      ) 
    )
  )
]).then(([module]) => module);
