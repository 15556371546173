/* global System */

import makeConfig from './make-config';
import loadAssets from './load-assets';
import fromQueryString from './query-string';
import loadOverlay from './load-overlay';
import loadScript from './load-script';

window.mqConfig = makeConfig(
  window.mqConfig,
  config => {
    const {push, mq, provider} = config;
    let href;

    if(!mq.noUrlParams)
      push(fromQueryString(location.search, config));

    const prerequisites = new Promise(
      (resolve, reject) => loadAssets(
        (inject, assetReady, injectProviderScripts) => {
          if(window.mqConfig.bwCompat) {
            href = `/js/map-${injectProviderScripts(provider)}.js`;

            inject(
              'link', {
                rel: 'preload',
                as: 'script',
                href
              }
            );
          } else {
            href = `/js/map-${injectProviderScripts(provider)}.mjs`;

            inject(
              'link', {
                rel: 'modulepreload',
                href
              }
            );
          }

          inject(
            'link', {
              rel: 'stylesheet',
              type: 'text/css',
              href: `/css/map.css`
            },
            assetReady()
          );

          return success => (success ? resolve() : reject(new Error('MapQuest components failed to load')));
        }
      )
    );

    mq.mapReady = () => prerequisites.then( () => (window.mqConfig.bwCompat ? System.import(href) : import(href)) );
    mq.load = loadOverlay();
    mq.loadScript = loadScript;
  }
);

