// Given a mask with N rightmost 1s, returns the next available 1
const nextMaskBit = m => (~m-1) & ~m ^ ~m;

export default boot => {
  let fullMask = 0, completionMask = 0, successMask = 0;

  const providers = {
    google() {
      inject(
        'script', {
          src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxFiDlZ37bSD9CPXC8KLJIMnk0ERzOPr8&callback=loadMapsCallback&libraries=places,directions',
          crossorigin: true
        },
        assetReady(null, onSuccess => window.loadMapsCallback = onSuccess )
      );
    },

    mapbox() {
      inject(
        'script', {
          src: 'https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.js',
          crossorigin: true
        },
        assetReady( () => window.mapboxgl.accessToken = 'pk.eyJ1IjoibWFwcXVlc3QiLCJhIjoiY2Q2N2RlMmNhY2NiZTRkMzlmZjJmZDk0NWU0ZGJlNTMifQ.mPRiEubbajc6a5y9ISgydg' )
      );

      inject(
        'link', {
          rel: 'stylesheet',
          type: 'text/css',
          href: 'https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css'
        },
        assetReady()
      );
    }
  }

  const complete = boot(inject, assetReady, injectProviderScripts);

  function injectProviderScripts(provider) {
    provider = provider.map || (provider.map = 'mapbox');
    providers[provider]();
    return provider;
  }

  function inject(type, props, [onload, onerror]=[]) {
    document.head.appendChild(
      Object.assign(
        document.createElement(type),
        props,
        {onload, onerror}
      )
    );
  }

  function assetReady(onSuccess, altSuccess, makeErrorInfo) {
    const
      mask = nextMaskBit(fullMask),
      success = () => {
        if(onSuccess)
          onSuccess();
        check(completionMask |= mask, successMask |= mask);
      }

    fullMask |= mask;

    if(altSuccess)
      altSuccess(success);

    return [
      altSuccess ? null : success,
      err => check(completionMask |= mask, makeErrorInfo && makeErrorInfo(err))
    ];
  }

  function check(completion, successMaskOrErrorInfo) {
    if(completion==fullMask)
      complete(completion==successMaskOrErrorInfo, successMaskOrErrorInfo);
  }
}

